<template>
  <div>
    <div
      class="form-group row mb-4"
    >
      <label class="col-12 col-md-2">{{ $t('timespan') }}</label>
      <div class="col-12 col-md-10">
        <select
          v-model="selectedPeriod"
          :class="['form-control m-input m-input--air', { 'is-invalid': this.$validator.errors.has('Sound') }]"
          @change="getSessionCount"
        >
          <option
            :value="null"
            disabled
          >
            {{ $t('pleaseSelectOne') }}
          </option>
          <option
            v-for="(period, periodIndex) in periods"
            :key="`themeElementEditFontFamilyOption-${ periodIndex }`"
            :value="period"
          >
            {{ period }}
          </option>
        </select>
        <span
          v-show="errors.has('Sound')"
          class="badge badge-danger"
        >{{ errors.first('Sound') }}</span>
      </div>
      <label class="col-12 col-md-5 mt-4">{{ $t('onlyFaulty') }}</label>
      <div class="col-10 col-md-7 mt-2 mb-2">
        <input
          v-model="onlyFaulty"
          type="checkbox"
          class="mt-4"
          @change="getSessionCount"
        >
      </div>
      <label class="col-12 mt-3">{{ $t('videoPublisherComp.reprocessingAffect') }} {{ affectedSessions ? affectedSessions : 0 }} {{ $t('sessions') }}!</label>
      <div class="col-12">
        <button
          class="btn btn-primary float-right"
          @click="reprocessByTime()"
        >
          <font-awesome-icon
            class="mr-2"
            icon="play"
          />{{ $t('videoPublisherComp.reprocess') }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: 'VideoPublisherReprocess',
  mixins: [
    dateTimeMixin,
  ],
  props: {
    installationId: {
      type: String,
      required: true
    },
    laneNumber: {
      type: String,
      required: false,
      default () {
        return null;
      }
    }
  },
  data () {
    return {
      selectedPeriod: 'last hour', // default value
      periods: ['last hour','last 6 hours','last 12 hours','last 24 hours', 'all-time'],
      affectedSessions: null,
      onlyFaulty: false
    }
  },
  computed: {
    referenceDateTime () {
      let date = new Date();
      switch(this.selectedPeriod) {
        case 'last hour':       
          return this.dateTime_isoString(new Date(date.getTime() - (1000*60*60)));
        case 'last 6 hours':
          return this.dateTime_isoString(new Date(date.getTime() - (1000*60*60*6)));
        case 'last 12 hours':
          return this.dateTime_isoString(new Date(date.getTime() - (1000*60*60*12)));
        case 'last 24 hours':
          return this.dateTime_isoString(new Date(date.getTime() - (1000*60*60*24)));
        case 'all-time':
          return this.dateTime_isoString(new Date('1970-01-01Z00:00:00:001'));
        default:
          return this.dateTime_isoString(new Date(date.getTime() - (1000*60*60*24)));
      }
    },
    now () {
      return this.dateTime_isoString(new Date());
    }
  },
  created (){
    this.getSessionCount();
  },
  methods: {
    reprocessByTime () {
      let dateRangeDto = {
        'from': this.referenceDateTime,
        'to': this.now,
        'onlyFaulty': this.onlyFaulty
      };
      let url = `/VideoPublisher/ReprocessByDateRange/${ this.installationId }`; 
      if (this.laneNumber)
      {
        url += `?laneNumber=${ this.laneNumber }`;
      }
      this.axios.post(url, dateRangeDto)
        .then((response) => {
          if (!response) {
            return;
          }
          if (!response.status) {
            return;
          }
          if (response.status != 200) {
            return;
          }
          this.$snotify.success(this.$t('videoPublisherComp.reprocessingSessionsSuccesfull'));
          this.$emit('refresh');
        });
    },
    getSessionCount ()
    {
      let url = `/VideoPublisher/GetReprocessSessionCountByDateRange/${ this.installationId }?from=${this.referenceDateTime}&to=${this.now}&faulty=${this.onlyFaulty}`; 
      if (this.laneNumber)
      {
        url += `&laneNumber=${ this.laneNumber }`;
      }
      this.axios.get(url)
        .then((response) => {
            this.affectedSessions = response.data;
        });
    }
  }
}
</script>

